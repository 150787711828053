const codComButton = document.querySelector(".codCom-input button");
const codComInput = document.querySelector(".codCom-input input");


codComButton.addEventListener("click", (e) => {
  e.preventDefault();
  console.log(codComInput.value);
  window.location.href = `/comercio.html?compID=${codComInput.value}`;
});

